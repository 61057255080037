import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import cn from 'classnames';
import { Tooltip } from 'components';
import { AuthProviders } from 'enums';
import { useUnpaidSubscription } from 'hooks';
import { useActivateAccount, useDeactivateAccount } from 'hooks/api';
import { useUserInfo } from 'hooks/api/useUserInfo';

import { AccountInfo } from '../AccountInfo';
import { ChangePassword } from '../ChangePassword';
import { UserAvatar } from '../UserAvatar';

import styles from './styles.module.scss';

export const ProfileSettings = () => {
  const { t } = useTranslation();
  const { data: userInfo, isSuccess } = useUserInfo();
  const { unpaidSubscription } = useUnpaidSubscription();
  const { mutate: activateAccount, isPending: isActivating } =
    useActivateAccount();
  const { mutate: deactivateAccount, isPending: isDeactivating } =
    useDeactivateAccount();

  const isGoogleUser =
    isSuccess &&
    userInfo?.provider?.includes(AuthProviders.Google) &&
    !userInfo?.provider?.includes(AuthProviders.Base);

  const activate = () => activateAccount();
  const deactivate = () => deactivateAccount();

  const accountStatusData = userInfo?.deactivatedAt
    ? {
        title: 'Page.Profile.Settings.Activate.Title',
        description: 'Page.Profile.Settings.Activate.Description',
        button: 'Page.Profile.Settings.Activate.Button',
        onClick: activate,
        disabled: false,
        variant: 'contained'
      }
    : {
        title: 'Page.Profile.Settings.Deactivate.Title',
        description: 'Page.Profile.Settings.Deactivate.Description',
        button: 'Page.Profile.Settings.Deactivate.Button',
        onClick: deactivate,
        variant: 'outlined',
        disabled: !!unpaidSubscription,
        tooltip: t('Page.Profile.Settings.Deactivate.UnpaidPlanTooltip')
      };

  return (
    <div className={styles.container}>
      <UserAvatar />
      <AccountInfo isGoogleUser={isGoogleUser} />

      {!isGoogleUser && (
        <>
          <h2 className={cn(styles.heading, styles['with-indentation'])}>
            {t('Page.Profile.Settings.ChangePassword')}
          </h2>
          <ChangePassword />
        </>
      )}

      <div className={styles['status-container']}>
        <div className={styles.details}>
          <span className={styles.title}>{t(accountStatusData.title)}</span>
          <span className={styles.subtitle}>
            {t(accountStatusData.description)}
          </span>
        </div>
        <div className={styles.actions}>
          <Tooltip
            title={accountStatusData.tooltip || ''}
            disabled={!accountStatusData.disabled}
            placement="bottom-end"
          >
            <span>
              <LoadingButton
                size="small"
                color="primary"
                className={styles.button}
                disabled={accountStatusData.disabled}
                onClick={accountStatusData.onClick}
                loading={isActivating || isDeactivating}
                variant={accountStatusData.variant as 'contained' | 'outlined'}
                classes={{
                  loadingIndicator: styles.spinner
                }}
              >
                {t(accountStatusData.button)}
              </LoadingButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
