import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel';

import { useUserInfo } from './api';
import { useAuthContext } from './useAuthContext';

export const useMixpanelAnalytics = () => {
  const location = useLocation();
  const { data: userInfo } = useUserInfo();
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated && userInfo) {
      mixpanel?.alias(userInfo.id);
      mixpanel?.identify(userInfo.id);

      mixpanel?.people.set({
        Email: userInfo.email,
        'First Name': userInfo.firstName,
        'Last Name': userInfo.lastName,
        'Subscription Plan': userInfo.subscriptionPlan,
        'Preferred Language': userInfo.preferredLanguage,
        Gender: userInfo.gender,
        Company: userInfo.company,
        Deactivated: !!userInfo.deactivatedAt,
        Provider: userInfo.provider ? userInfo.provider.join(', ') : null
      });
    }
  }, [isAuthenticated, userInfo]);

  useEffect(() => {
    mixpanel?.track_pageview();
  }, [location]);
};
