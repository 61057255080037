import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const LimitedCompanyListNote = memo(() => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <strong className={styles.title}>
        {t('Page.Chat.LimitedCompanyListNote.Title')}
      </strong>
      <span className={styles.description}>
        {t('Page.Chat.LimitedCompanyListNote.Description')}
      </span>
      <a
        className={styles.cta}
        target="_blanc"
        href="https://calendar.app.google/KpuQuadq9grySrRx8"
      >
        {t('Page.Chat.LimitedCompanyListNote.Cta')}
      </a>
    </div>
  );
});
