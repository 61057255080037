import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/ArrowLeftIcon.svg';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  className?: string;
  children?: ReactNode;
}

export const TitleWithBackButton: FC<Props> = ({
  title,
  children,
  className
}) => {
  const navigate = useNavigate();
  const handleBackArrowClick = () => {
    navigate(-1);
  };

  return (
    <h1 className={cn(styles.title, className)}>
      <ArrowLeftIcon className={styles.back} onClick={handleBackArrowClick} />
      {title || children}
    </h1>
  );
};
