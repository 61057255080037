import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Collapse } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as CrossIcon } from 'assets/cross-circle.svg';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';
import { ReactComponent as MaximizeIcon } from 'assets/maximize.svg';
import { ReactComponent as MinimizeIcon } from 'assets/minimize.svg';
import cn from 'classnames';
import { CommonTooltip } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardFilter } from 'entities/DashboardFIlter.entity';
import { InsightsType, queryKeys, Routes } from 'enums';
import {
  DashboardFilterTypeColor,
  DashboardFilterTypeLabel
} from 'enums/DashboardFilterType.enum';
import { useIsExternalUserDashboard } from 'hooks';
import {
  useCreatedNestedDashboard,
  useUserCoins,
  useUserInfo
} from 'hooks/api';
import { useChatById } from 'hooks/api/useChatById';

import { BackdropOverlay } from '../BackdropOverlay';
import { DashboardVariationLimitModal } from '../DashboardVariationLimitModal';
import { findDuplicatedDashboardFilters } from './helpers';
import { NoCoinsModal } from './NoCoinsModal';
import { RemoveFilterModal } from './RemoveFilterModal';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  data: DashboardData['filters'];
}

export const FloatingFilter: FC<Props> = ({ data, className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chatId, dashboardId, messageId } = useParams();
  const [expanded, setIsExpanded] = useState(false);
  const [isVariationLimitModalOpen, setVariationLimitModalOpen] =
    useState(false);
  const [isRemoveFilterModalOpen, setRemoveFilterModalOpen] = useState(false);
  const [isNoCoinsModalOpen, setNoCoinsModalOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState<DashboardFilter[]>([]);

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const isExternalUserDashboard = useIsExternalUserDashboard();

  const dashboardHistoryWidget = dashboardData?.widgets?.find(
    ({ type }) => type === InsightsType.DashboardHistory
  );

  const expandFilter = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const collapseFilter = useCallback(() => setIsExpanded(false), []);

  const openRemoveFilterModal = useCallback(
    () => setRemoveFilterModalOpen(true),
    []
  );

  const closeRemoveFilterModal = useCallback(() => {
    setActiveFilter([]);
    setRemoveFilterModalOpen(false);
  }, []);

  const openNoCoinsModal = useCallback(() => setNoCoinsModalOpen(true), []);

  const closeNoCoinsModal = useCallback(() => setNoCoinsModalOpen(false), []);

  const openVariationLimitModal = useCallback(
    () => setVariationLimitModalOpen(true),
    []
  );

  const closeVariationLimitModal = useCallback(
    () => setVariationLimitModalOpen(false),
    []
  );

  const { mutate: fetchChatData } = useChatById();

  const mergedFiltersByType = useMemo(() => {
    const map = new Map<string, string[]>();

    data?.forEach((item) => {
      if (map.has(item.typeName)) {
        map.get(item.typeName)!.push(item.name);
      } else {
        map.set(item.typeName, [item.name]);
      }
    });

    return Array.from(map, ([typeName, names]) => ({
      name: names.join(', '),
      typeName
    }));
  }, [data]);

  const onSuccess = () => {
    if (dashboardData?.chatId) {
      fetchChatData({ chatId: dashboardData.chatId });
    }
  };

  const { mutate: createNestedDashboard, isPending } =
    useCreatedNestedDashboard({
      onSuccess,
      showVariationLimitModal: openVariationLimitModal,
      showNoCoinsModal: openNoCoinsModal
    });

  const hasCoins = userInfo?.isPremiumUser ? true : userCoins?.count;

  const onRemoveFilter = (row: { typeName: string; name: string }) => {
    const newFilters = data?.filter(
      ({ typeName }) => typeName !== row.typeName
    );

    if (dashboardHistoryWidget) {
      const isDashboardAlreadyGenerated = !!findDuplicatedDashboardFilters(
        dashboardHistoryWidget.params?.data || [],
        newFilters || []
      );

      if (isDashboardAlreadyGenerated) {
        createNestedDashboard({
          dashboardId: dashboardId || '',
          chatId: chatId || '',
          originDashboardId: dashboardData?.originDashboardId,
          data: newFilters || []
        });
      } else if (!hasCoins) {
        openNoCoinsModal();
      } else if (!isDashboardAlreadyGenerated) {
        setActiveFilter(newFilters || []);
        openRemoveFilterModal();
      }
    }
  };

  const createNewDashboard = () => {
    createNestedDashboard({
      dashboardId: dashboardId || '',
      chatId: chatId || '',
      originDashboardId: dashboardData?.originDashboardId,
      data: activeFilter
    });

    setActiveFilter([]);
  };

  const handleNoCoinsModalConfirm = () => {
    navigate(Routes.SubscriptionPlans, {
      state: { scrollToTop: true }
    });
  };

  return (
    <div className={cn(styles.container, className)}>
      {isPending && <BackdropOverlay isPending={isPending} />}
      <DashboardVariationLimitModal
        onClose={closeVariationLimitModal}
        isOpen={isVariationLimitModalOpen}
      />
      <NoCoinsModal
        isOpen={isNoCoinsModalOpen}
        onClose={closeNoCoinsModal}
        onConfirm={handleNoCoinsModalConfirm}
      />
      <RemoveFilterModal
        onConfirm={createNewDashboard}
        onClose={closeRemoveFilterModal}
        isOpen={isRemoveFilterModalOpen}
      />
      <div className={styles.header}>
        <div className={styles['title-container']}>
          <FilterIcon className={styles['filter-icon']} />
          <span className={styles.title}>
            {t('Page.Dashboard.AppliedFilters', {
              amount: mergedFiltersByType.length
            })}
          </span>
        </div>
        {expanded ? (
          <MaximizeIcon
            onClick={collapseFilter}
            className={styles['expand-icon']}
          />
        ) : (
          <MinimizeIcon
            onClick={expandFilter}
            className={styles['expand-icon']}
          />
        )}
      </div>
      <Collapse unmountOnExit in={expanded}>
        <div className={styles.content}>
          {mergedFiltersByType.map((row) => (
            <div key={row.name} className={styles.row}>
              <div className={styles['row-content']}>
                <div
                  className={styles.color}
                  style={{
                    // @ts-expect-error type error
                    backgroundColor: DashboardFilterTypeColor[row.typeName]
                  }}
                />
                <CommonTooltip
                  className={cn(styles['row-title'], 'overflowed-text')}
                  title={`${
                    // @ts-expect-error type error
                    t(DashboardFilterTypeLabel[row.typeName])
                  }:`}
                />
                <CommonTooltip
                  title={row.name}
                  className={cn(styles['row-value'], 'overflowed-text')}
                />
              </div>
              <CrossIcon
                className={cn(
                  styles['cross-icon'],
                  (userInfo?.deactivatedAt || isExternalUserDashboard) &&
                    styles.disabled
                )}
                onClick={() => onRemoveFilter(row)}
              />
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};
