import { LocalStorage } from 'services/LocalStorage';
import { SessionStorage } from 'services/SessionStorage';
import { USER_AUTHENTICATED } from 'utils/constants';

export const setIsUserAuthenticated = (isAuthenticated: boolean): void => {
  LocalStorage.setItem(USER_AUTHENTICATED, isAuthenticated);
};

export const clearStorage = (): void => {
  LocalStorage.reset();
  SessionStorage.reset();
};
