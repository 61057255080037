import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Modal, SubTitle } from 'components';
import { format } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { DashboardLimitTimeRanges, SubscriptionCycles } from 'enums';
import { useContactGieniUrl, useLanguage } from 'hooks';
import { useActiveSubscription, useUserCoins } from 'hooks/api';

import styles from './styles.module.scss';

export const DashboardLimitModal = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const { data: userCoins } = useUserCoins();
  const { data: activeSubscription } = useActiveSubscription();
  const [timeRange, setTimeRange] = useState<DashboardLimitTimeRanges>(
    DashboardLimitTimeRanges.Daily
  );
  const contactPageUrl = useContactGieniUrl();
  const handleClose = () => setIsOpen(false);

  const handleButtonClick = () => {
    window.open(contactPageUrl, '_blank');
  };

  useEffect(() => {
    if (activeSubscription) {
      // @ts-expect-error error type
      window.openDashboardLimitModal = (
        newTimeRange: DashboardLimitTimeRanges
      ) => {
        setTimeRange(newTimeRange);
        setIsOpen(true);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscription]);

  const date =
    activeSubscription?.period === SubscriptionCycles.Yearly
      ? userCoins?.monthlyDashboardRequestsRefreshDate
      : activeSubscription?.expiresAt;

  const locale = language === 'de' ? de : enUS;

  return activeSubscription && userCoins && date ? (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className={styles.container}
      title={t(`Common.CreateDashboardLimit.${timeRange}.Title`)}
      titleClassName={styles.title}
    >
      <SubTitle className={styles.description}>
        {t(`Common.CreateDashboardLimit.${timeRange}.Description`, {
          date: format(date || '', 'LLL d, y', { locale })
        })}
      </SubTitle>

      <Button
        size="medium"
        color="primary"
        variant="contained"
        onClick={handleButtonClick}
        className={styles.button}
      >
        {t(`Common.CreateDashboardLimit.Button`)}
      </Button>
    </Modal>
  ) : null;
};
