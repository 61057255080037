import { type Dispatch, type SetStateAction } from 'react';
import { useMutation } from '@tanstack/react-query';
import { signIn, SigninPayload } from 'actions/authActions';
import { HttpStatusCode } from 'axios';
import { ApiError } from 'entities/ApiError.entity';
import { useResendEmail } from 'hooks/api/useResendEmail';
import { useSnackbar } from 'hooks/useSnackbar';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { LocalStorage } from 'services/LocalStorage';
import { SHOW_LOGIN_MODAL } from 'utils/constants';
import { setIsUserAuthenticated } from 'utils/helpers/authHelpers';

export const useSignIn = (
  handleReset: () => void,
  setAuthenticated: Dispatch<SetStateAction<boolean>>
) => {
  const snackbar = useSnackbar();
  const { mutate: handleResend } = useResendEmail();

  return useMutation<void, ApiError, SigninPayload>({
    mutationFn: async (payload: SigninPayload) => {
      await signIn(payload);
    },
    onSuccess(_, { userData }) {
      setIsUserAuthenticated(true);
      LocalStorage.setItem(SHOW_LOGIN_MODAL, true);
      setAuthenticated(true);

      mixpanel?.track(MixpanelEvents.Signin, {
        Email: userData.email
      });
    },
    onError(error, variables) {
      const isEmailNotConfirmed =
        error?.statusCode === HttpStatusCode.Unauthorized &&
        error?.message === 'User email not confirmed';

      if (isEmailNotConfirmed) {
        const {
          userData: { email },
          question,
          appRedirectPath
        } = variables;

        snackbar.error.emailNotConfirmedError(error, () =>
          handleResend({ email, question, appRedirectPath })
        );
      } else {
        snackbar.error.commonError(error);
      }

      handleReset();
    }
  });
};
