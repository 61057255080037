import { createContext, useContext } from 'react';
import { SigninPayload, SignupPayload } from 'actions/authActions';

export type UserData = {
  email: string;
  password: string;
};

interface AuthContextData {
  isAuthenticated: boolean;
  isLoading: boolean;
  error?: string;
  logOut: () => void;
  signIn: (data: SigninPayload) => void;
  signUp: (data: SignupPayload) => void;
}

export const authContext = createContext<AuthContextData>({
  isAuthenticated: false,
  isLoading: false,
  error: '',
  signIn: () => {},
  signUp: () => {},
  logOut: () => {}
});

export const AuthContextProvider = authContext.Provider;

export const useAuthContext = (): AuthContextData => useContext(authContext);
