import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { AuthLayout, Card, Title } from 'components';
import { EmailTypes, Routes } from 'enums';
import { useAuthContext, useSnackbar } from 'hooks';
import { useUpdateEmailPreferences, useUserInfo } from 'hooks/api';

import styles from './styles.module.scss';

export const Unsubscribe: FC = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuthenticated } = useAuthContext();
  const { data: userInfo } = useUserInfo();
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get('token');
  let email = token?.split('.')?.[1];
  email = email?.replace(/\s/g, '');
  const emailDecoded = email && JSON.parse(atob(email))?.sub;

  const redirectUser = () => {
    if (isAuthenticated && userInfo?.email !== emailDecoded) {
      return;
    }

    navigate(isAuthenticated ? Routes.NewChat : Routes.Login);
  };

  const onSuccess = () => {
    searchParams.delete('token');
    setSearchParams(searchParams);
    redirectUser();
  };

  const {
    isError,
    isPending,
    mutate: updateEmailPreferences
  } = useUpdateEmailPreferences(onSuccess);

  useEffect(() => {
    if (!token) {
      navigate(isAuthenticated ? Routes.NewChat : Routes.Login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (token) {
      updateEmailPreferences({
        token,
        type: EmailTypes.FEEDBACK,
        isSubscribed: false
      });
    }
  };

  const onCancel = () => {
    snackbar.success.commonSuccess(t('Page.Auth.Unsubscribe.ChangesSaved'));
    redirectUser();
  };

  const displayedEmail = useMemo(() => {
    if (!isAuthenticated) {
      return emailDecoded;
    }

    return userInfo?.email === emailDecoded ? userInfo?.email : emailDecoded;
  }, [emailDecoded, isAuthenticated, userInfo?.email]);

  return (
    <AuthLayout>
      <Card className={styles.card}>
        {!isError && (
          <>
            <Title
              className={styles.title}
              title={t('Page.Auth.Unsubscribe.Title')}
            />
            <div className={styles.subtitle}>
              {t('Page.Auth.Unsubscribe.Subtitle', { email: displayedEmail })}
            </div>

            <div className={styles.actions}>
              <LoadingButton
                fullWidth
                size="medium"
                color="primary"
                variant="outlined"
                type="submit"
                loading={isPending}
                onClick={onSubmit}
              >
                {t('Page.Auth.Unsubscribe.Confirm')}
              </LoadingButton>
              <Button
                fullWidth
                size="medium"
                color="primary"
                variant="contained"
                onClick={onCancel}
              >
                {t('Page.Auth.Unsubscribe.Cancel')}
              </Button>
            </div>
          </>
        )}
      </Card>
    </AuthLayout>
  );
};
