import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Modal, SubTitle } from 'components';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const RemoveFilterModal: FC<Props> = ({
  isOpen = false,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();

  const handleCancel = () => onClose();

  const handleConfirm = () => onConfirm();

  return (
    <Modal
      isOpen={isOpen}
      className={styles.container}
      titleClassName={styles.title}
      title={t('Page.Dashboard.RemoveFilterModal.Title')}
    >
      <SubTitle className={styles.subtitle}>
        {t('Page.Dashboard.RemoveFilterModal.Subtitle')}
      </SubTitle>

      <div className={styles.buttons}>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleConfirm}
          className={styles.button}
        >
          {t('Page.Dashboard.RemoveFilterModal.Confirm')}
        </Button>
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          onClick={handleCancel}
          className={styles.button}
        >
          {t('Page.Dashboard.RemoveFilterModal.Cancel')}
        </Button>
      </div>
    </Modal>
  );
};
