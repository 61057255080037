import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { createChat } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Chat } from 'entities/Chat.entity';
import { Message } from 'entities/Message.entity';
import { MessageTypes } from 'enums';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';
import { MixpanelEvents } from 'mixpanel';

export const useCreateChat = (
  chatId: string | undefined,
  setMessages: Dispatch<SetStateAction<Message[] | null>>,
  sendMessageMutation: UseMutateFunction<
    {
      id: string;
      messageId: string;
      statement: string;
      dashboardId: string;
    } | void,
    ApiError,
    {
      message: string;
      currentChatId: string;
      mixpanelEvent?: MixpanelEvents;
    },
    unknown
  >
) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  return useMutation<
    Chat,
    ApiError,
    {
      message?: string;
      subject?: string;
      title?: string;
      mixpanelEvent?: MixpanelEvents;
    }
  >({
    mutationFn: ({ subject, title }) => createChat({ subject, title }),
    onSuccess: (data, { message, subject, mixpanelEvent }) => {
      if (message)
        sendMessageMutation({ message, currentChatId: data.id, mixpanelEvent });

      if (!chatId) {
        navigate(Routes.Chat.replace(':chatId', data.id), {
          replace: true
        });
      }

      if (subject) {
        queryClient.resetQueries({
          queryKey: queryKeys.filteredChats({ includeArchived: false })
        });

        queryClient.resetQueries({
          queryKey: queryKeys.filteredChats({ includeArchived: true })
        });
      }
    },
    onError(error, { message }) {
      if (error?.message === 'User is inactive') {
        const id = `error-${Math.random()}`;
        const question = Message.deserialize({
          statement: message,
          type: MessageTypes.question
        });
        const answer = Message.deserialize({
          id,
          messageId: id,
          statement: t('Page.Chat.DeactivatedAccountErrorDefaultAnswer'),
          type: MessageTypes.answer,
          activateAccountVisible: true,
          requestMoreDataVisible: false
        });

        if (message) {
          setMessages((messages) => [answer, question, ...(messages || [])]);
        }
      } else {
        snackbar.error.commonError(error);
      }
    }
  });
};
