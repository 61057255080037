import { useUserInfo } from './api';
import { useLandingUrl } from './useLandingUrl';

export const useContactGieniUrl = () => {
  const { data: userInfo } = useUserInfo();
  const landigUrl = useLandingUrl();

  if (!userInfo) return `${landigUrl}/contact`;

  const { firstName, lastName, email, company } = userInfo;

  const contactGieniUrl = new URL(`${landigUrl}/contact`);

  contactGieniUrl.searchParams.set('firstName', firstName);
  contactGieniUrl.searchParams.set('lastName', lastName);
  contactGieniUrl.searchParams.set('email', email);

  if (company) {
    contactGieniUrl.searchParams.set('company', company);
  }

  return contactGieniUrl.toString();
};
