import { useMutation } from '@tanstack/react-query';
import { fetchDashboardById } from 'actions/dashboardActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardLimitTimeRanges, queryKeys } from 'enums';
import { ApiErrors } from 'enums/ApiErrors.enum';
import { queryClient } from 'index';

import { useSnackbar } from '../useSnackbar';

export const useFetchDashboardData = () => {
  const snackbar = useSnackbar();

  return useMutation<
    DashboardData,
    ApiError,
    { dashboardId: string; onSuccess?: () => void }
  >({
    mutationFn: ({ dashboardId }) => fetchDashboardById(dashboardId),
    onSuccess: (data, { dashboardId, onSuccess }) => {
      queryClient.setQueryData(queryKeys.dashboardDetails(dashboardId!), data);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError(error) {
      if (error?.message === ApiErrors.PremiumMonthlyDashboardsLimit) {
        // @ts-expect-error error type
        window?.openDashboardLimitModal(DashboardLimitTimeRanges.Monthly);
      } else if (error?.message === ApiErrors.PremiumDailyDashboardsLimit) {
        // @ts-expect-error error type
        window?.openDashboardLimitModal(DashboardLimitTimeRanges.Daily);
      } else {
        snackbar.error.commonError(error);
      }
    }
  });
};
