import { FC, useCallback, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import { PasswordData } from 'actions/userActions';
import { PasswordAdornment, Tooltip } from 'components';
import { useUserInfo } from 'hooks/api';
import { useChangeUserPassword } from 'hooks/api/useChangeUserPassword';
import {
  validatePasswordIsNotLikeEmail,
  validatePasswordPattern,
  validateRequired
} from 'utils/helpers/validators';

import styles from './styles.module.scss';

type PasswordForm = PasswordData & {
  repeatPassword?: string;
};

export const ChangePassword: FC = () => {
  const { t } = useTranslation();

  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
  const [isNewPasswordVisible, setNewPasswordVisibility] =
    useState<boolean>(false);
  const [isRepeatPasswordVisible, setRepeatPasswordVisibility] =
    useState<boolean>(false);

  const { data: userInfo } = useUserInfo();
  const { mutate: updateUserPassword, isPending: isUserInfoUpdating } =
    useChangeUserPassword();

  const methods = useForm<PasswordForm>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      repeatPassword: ''
    }
  });

  const isFormEdited = methods.formState.isDirty;

  const onSubmit = useCallback(
    ({ oldPassword, newPassword }: PasswordForm) => {
      updateUserPassword({
        oldPassword,
        newPassword
      });

      methods.reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateUserPassword]
  );

  const isAccountDeactivated = !!userInfo?.deactivatedAt;

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={styles.container}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className={styles.label}>{t('Form.Label.OldPassword')}</div>
          <Controller
            name="oldPassword"
            rules={{
              validate: (value: PasswordForm['oldPassword']) => {
                if (!validateRequired(value)) {
                  return t('Form.Validation.Password.OldRequired');
                }

                return true;
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="medium"
                id="oldPassword"
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder={t('Form.Label.OldPassword')}
                error={!!methods.formState.errors.oldPassword?.message}
                helperText={methods.formState.errors.oldPassword?.message}
                className={styles['input-container']}
                InputProps={{
                  className: styles.input,
                  endAdornment: (
                    <PasswordAdornment
                      isVisible={isPasswordVisible}
                      onTogglePassword={() =>
                        setPasswordVisibility((value) => !value)
                      }
                    />
                  )
                }}
              />
            )}
          />
          <div className={styles.label}>{t('Form.Label.NewPassword')}</div>
          <Controller
            name="newPassword"
            rules={{
              validate: (value: PasswordForm['newPassword']) => {
                if (!validateRequired(value)) {
                  return t('Form.Validation.Password.NewRequired');
                }

                if (
                  !validatePasswordIsNotLikeEmail(value, userInfo?.email || '')
                ) {
                  return t('Form.Validation.Password.LikeEmail');
                }

                if (!validatePasswordPattern(value)) {
                  return t('Form.Validation.Password.NotValid');
                }

                return true;
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="medium"
                id="newPassword"
                type={isNewPasswordVisible ? 'text' : 'password'}
                placeholder={t('Form.Label.NewPassword')}
                error={!!methods.formState.errors.newPassword?.message}
                helperText={methods.formState.errors.newPassword?.message}
                className={styles['input-container']}
                InputProps={{
                  className: styles.input,
                  endAdornment: (
                    <PasswordAdornment
                      isVisible={isNewPasswordVisible}
                      onTogglePassword={() =>
                        setNewPasswordVisibility((value) => !value)
                      }
                    />
                  )
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 32
                }}
              />
            )}
          />

          <div className={styles.label}>{t('Form.Label.RepeatPassword')}</div>
          <Controller
            name="repeatPassword"
            rules={{
              validate: (value: PasswordForm['newPassword']) => {
                if (!validateRequired(value)) {
                  return t('Form.Validation.Password.RepeatRequired');
                }

                if (methods.getValues()?.newPassword !== value) {
                  return t('Form.Validation.Password.NotMatchPassword');
                }

                return true;
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="medium"
                id="repeatPassword"
                type={isRepeatPasswordVisible ? 'text' : 'password'}
                placeholder={t('Form.Label.RepeatPassword')}
                error={!!methods.formState.errors.repeatPassword?.message}
                helperText={methods.formState.errors.repeatPassword?.message}
                InputProps={{
                  className: styles.input,
                  endAdornment: (
                    <PasswordAdornment
                      isVisible={isRepeatPasswordVisible}
                      onTogglePassword={() =>
                        setRepeatPasswordVisibility((value) => !value)
                      }
                    />
                  )
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 32
                }}
              />
            )}
          />
          <Tooltip
            disabled={!isAccountDeactivated}
            title={t('Common.DeactivatedAccountNote')}
          >
            <div className={styles['button-container']}>
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                className={styles.submit}
                loading={isUserInfoUpdating}
                disabled={!isFormEdited || isAccountDeactivated}
              >
                {t('Common.SaveChanges')}
              </LoadingButton>
            </div>
          </Tooltip>
        </form>
      </FormProvider>
    </>
  );
};
