import { FC, ReactNode, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { PasswordAdornment } from 'components';
import {
  validateEmail,
  validatePasswordIsNotLikeEmail,
  validatePasswordPattern,
  validateRequired
} from 'utils/helpers/validators';

import styles from './styles.module.scss';

export type SignupFormData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  company: string;
  gender: string;
};

interface Props {
  isLoading: boolean;
  submitLabel: string;
  children?: ReactNode;
  onSubmit: (data: SignupFormData) => void;
}

export const GENDER_OPTIONS = [
  {
    value: 'Male',
    title: 'Form.GenderOptions.Male'
  },
  {
    value: 'Female',
    title: 'Form.GenderOptions.Female'
  },
  {
    value: 'I prefer not to say',
    title: 'Form.GenderOptions.Other'
  }
];

export enum GenderLabels {
  'Male' = 'Form.GenderOptions.Male',
  'Female' = 'Form.GenderOptions.Female',
  'I prefer not to say' = 'Form.GenderOptions.Other'
}

export const SignupForm: FC<Props> = ({
  onSubmit,
  isLoading,
  submitLabel,
  children
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors }
  } = useFormContext<SignupFormData>();

  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.label}>{t('Form.Label.FirstName')}</div>
      <Controller
        name="firstName"
        rules={{
          validate: (value: SignupFormData['firstName']) => {
            const trimmedFirstName = value.trim();
            if (!validateRequired(trimmedFirstName)) {
              return t('Form.Validation.FirstName.Required');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            id="firstName"
            size="medium"
            placeholder={t('Form.Placeholder.FirstName')}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            className={styles['input-container']}
            inputProps={{
              className: styles.input
            }}
          />
        )}
      />

      <div className={styles.label}>{t('Form.Label.LastName')}</div>
      <Controller
        name="lastName"
        rules={{
          validate: (value: SignupFormData['lastName']) => {
            const trimmedLastName = value.trim();
            if (!validateRequired(trimmedLastName)) {
              return t('Form.Validation.LastName.Required');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            id="lastName"
            size="medium"
            placeholder={t('Form.Placeholder.LastName')}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            className={styles['input-container']}
            inputProps={{
              className: styles.input
            }}
          />
        )}
      />

      <div className={styles.label}>{t('Form.Label.Gender')}</div>
      <Controller
        name="gender"
        rules={{
          validate: (value: SignupFormData['gender']) => {
            if (!value) {
              return t('Form.Validation.Gender.Required');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <div className={styles['input-container']}>
            <Select
              {...field}
              fullWidth
              id="gender"
              size="medium"
              displayEmpty
              renderValue={(value: string) => {
                if (!value) {
                  return (
                    <span className={styles.placeholder}>
                      {t('Form.Placeholder.Gender')}
                    </span>
                  );
                }
                // @ts-expect-error type error
                return t(GenderLabels[value]);
              }}
              MenuProps={{
                className: styles.menu,
                transitionDuration: 0
              }}
              input={
                <OutlinedInput
                  error={!!errors.gender}
                  inputProps={{
                    className: styles.input
                  }}
                />
              }
            >
              {GENDER_OPTIONS?.map(({ value, title }) => (
                <MenuItem key={value} value={value}>
                  {t(title)}
                </MenuItem>
              ))}
            </Select>
            {errors.gender?.message && (
              <p className={styles.error}>{errors.gender?.message}</p>
            )}
          </div>
        )}
      />

      <div className={styles.label}>{t('Form.Label.CompanyName')}</div>
      <Controller
        name="company"
        rules={{
          validate: (value: SignupFormData['company']) => {
            const trimmedCompany = value.trim();
            if (!validateRequired(trimmedCompany)) {
              return t('Form.Validation.CompanyName.Required');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            id="company"
            size="medium"
            placeholder={t('Form.Placeholder.ProfileCompanyName')}
            error={!!errors.company}
            helperText={errors.company?.message}
            className={styles['input-container']}
            inputProps={{
              maxLength: 100,
              className: styles.input
            }}
          />
        )}
      />

      <div className={styles.label}>{t('Form.Label.Email')}</div>
      <Controller
        name="email"
        rules={{
          validate: (value: SignupFormData['email']) => {
            const trimmedEmail = value.trim();
            if (!validateRequired(trimmedEmail)) {
              return t('Form.Validation.Email.Required');
            }

            if (!validateEmail(trimmedEmail)) {
              return t('Form.Validation.Email.NotValid');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            id="email"
            size="medium"
            placeholder={t('Form.Placeholder.Email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            className={styles['input-container']}
            inputProps={{
              className: styles.input
            }}
          />
        )}
      />

      <div className={styles.label}>{t('Form.Label.Password')}</div>
      <Controller
        name="password"
        rules={{
          validate: (
            value: SignupFormData['password'],
            formValues: SignupFormData
          ) => {
            if (!validateRequired(value)) {
              return t('Form.Validation.Password.Required');
            }

            if (!validatePasswordIsNotLikeEmail(value, formValues.email)) {
              return t('Form.Validation.Password.LikeEmail');
            }

            if (!validatePasswordPattern(value)) {
              return t('Form.Validation.Password.NotValid');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            size="medium"
            id="password"
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder={t('Form.Placeholder.Password')}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            className={styles['input-container']}
            InputProps={{
              className: styles.input,
              endAdornment: (
                <PasswordAdornment
                  isVisible={isPasswordVisible}
                  onTogglePassword={() =>
                    setPasswordVisibility((value) => !value)
                  }
                />
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              maxLength: 32
            }}
          />
        )}
      />
      {children}
      <input type="hidden" id="gclid_field" name="gclid_field" value="" />
      <LoadingButton
        size="medium"
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        className={styles.submit}
      >
        {submitLabel}
      </LoadingButton>
    </form>
  );
};
