import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SessionStorage } from 'services/SessionStorage';
import { REDIRECT_PATH } from 'utils/constants';

export const useRedirectFromSessionStorage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const redirectPath = SessionStorage.getItem(REDIRECT_PATH);

    if (redirectPath && searchParams.has('redirectFromSessionStorage')) {
      SessionStorage.removeItem(REDIRECT_PATH);

      if (window.location.pathname !== redirectPath) {
        navigate(redirectPath);
      }
    }
  }, [navigate, searchParams]);
};
