import { useMutation } from '@tanstack/react-query';
import { resendEmail, ResendEmailPayload } from 'actions/authActions';
import { ApiError } from 'entities/ApiError.entity';
import { useSnackbar } from 'hooks/useSnackbar';
import mixpanel, { MixpanelEvents } from 'mixpanel';

export const useResendEmail = () => {
  const snackbar = useSnackbar();

  return useMutation<void, ApiError, ResendEmailPayload>({
    mutationFn: async (payload) => {
      await resendEmail(payload);
    },
    onSuccess(_, { email }) {
      mixpanel?.track(MixpanelEvents.ResendConfirmationEmail, {
        Email: email
      });

      snackbar.success.confirmationEmailResendSuccess(email);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
