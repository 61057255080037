import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { Tooltip } from 'components';
import { SubscriptionStatuses } from 'enums';
import {
  useActiveSubscription,
  useCancelSubscription,
  useRenewSubscription,
  useUserInfo
} from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { ConfirmRenewSubscriptionModal } from './ConfirmRenewSubscriptionModal';

import styles from './styles.module.scss';

interface Props {
  renewalDate?: Date;
}
export const RenewSubscription: FC<Props> = ({ renewalDate }) => {
  const { t } = useTranslation();
  const { data: activeSubscription } = useActiveSubscription();
  const { data: userInfo } = useUserInfo();
  const { mutate: cancelSubscription } = useCancelSubscription();
  const { mutate: renewSubscription } = useRenewSubscription();
  const isSubscriptionActive =
    activeSubscription?.status === SubscriptionStatuses.Active;

  const [isCancelRenewalModalOpen, setCancelRenewalModalOpen] = useState(false);

  const openCancelRenewalModal = useCallback(
    () => setCancelRenewalModalOpen(true),
    []
  );
  const closeCancelRenewalModal = useCallback(
    () => setCancelRenewalModalOpen(false),
    []
  );

  const handleToggleChange = () => {
    if (isCancelRenewalModalOpen) {
      closeCancelRenewalModal();
    }

    if (isSubscriptionActive) {
      cancelSubscription();
    } else {
      renewSubscription();
    }

    mixpanel?.track(MixpanelEvents.ToggleStatusChanged, {
      'User ID': userInfo?.id,
      Email: userInfo?.email,
      'Date/Time of Status Change': userInfo?.createdAt,
      'New Status': isSubscriptionActive ? 'Disabled' : 'Enabled'
    });
  };

  return (
    <div className={styles.container}>
      <ConfirmRenewSubscriptionModal
        renewalDate={renewalDate}
        onSubmit={handleToggleChange}
        isOpen={isCancelRenewalModalOpen}
        onCancel={closeCancelRenewalModal}
      />
      <div className={styles.details}>
        <span className={styles.title}>
          {t('Page.Profile.Billing.RenewSubscriptionTitle')}
        </span>
        <span className={styles.subtitle}>
          {t('Page.Profile.Billing.RenewSubscriptionSubtitle')}
        </span>
      </div>
      <div className={styles.actions}>
        <Tooltip
          title={t(
            'Page.Profile.Billing.AutoSubscriptionDeactivatedAccountTooltip'
          )}
          disabled={!userInfo?.deactivatedAt}
          className={styles.tooltip}
        >
          <span>
            <Switch
              onColor="#FFA51C"
              offColor="#8f8f8f"
              offHandleColor="#ffffff"
              onHandleColor="#ffffff"
              width={40}
              height={24}
              handleDiameter={16}
              activeBoxShadow="0"
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={
                isSubscriptionActive
                  ? openCancelRenewalModal
                  : handleToggleChange
              }
              disabled={!!userInfo?.deactivatedAt}
              checked={isSubscriptionActive && !userInfo?.deactivatedAt}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
