import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { Tooltip } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';
import { useExternalDashboardLink, useIsExternalUserDashboard } from 'hooks';
import { useUserInfo } from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { MoreDataModal } from 'views/Conversation/MoreDataModal';

import styles from './styles.module.scss';

export const FullReportsAd = () => {
  const { t } = useTranslation();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const externalDashboardLink = useExternalDashboardLink();
  const { dashboardId } = useParams();
  const { data: userInfo } = useUserInfo();

  const [isMoreDataModalOpen, setMoreDataModalOpen] = useState(false);

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  const defaultSelectedProducts = [
    t('Common.MoreDataModal.Products.CompetitorAnalysis'),
    t('Common.MoreDataModal.Products.MarketAnalysis'),
    t('Common.MoreDataModal.Products.TrendAnalysis'),
    t('Common.MoreDataModal.Products.RiskAnalysis'),
    t('Common.MoreDataModal.Products.TechnologyComparison')
  ];

  const handleCtaClick = () => {
    setMoreDataModalOpen(true);
  };

  const onSubmitProductsSelection = (
    products: string,
    additionalInfo?: string
  ) => {
    mixpanel?.track(MixpanelEvents.DatalakeRequested, {
      'Dashboard ID': dashboardId,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': externalDashboardLink,
      Products: products,
      'Additional Information': additionalInfo,
      'Default Industries': dashboardData?.rootIndustries?.join(', ') || '-',
      'Nested Industries': dashboardData?.nestedIndustries?.join(', ') || '-'
    });
  };

  const handleCloseModal = () => {
    setMoreDataModalOpen(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h2 className={styles.title}>
            {t('Page.Dashboard.FullReportsAd.Title')}
          </h2>
          <span className={styles.subtitle}>
            {t('Page.Dashboard.FullReportsAd.Subtitle')}
          </span>
        </div>

        <Tooltip
          disabled={!userInfo?.deactivatedAt}
          title={t('Common.DeactivatedAccountNote')}
        >
          <span className="flex">
            <Button
              size="medium"
              color="primary"
              variant="contained"
              onClick={handleCtaClick}
              className={cn(styles.cta, 'brilliance')}
              disabled={isExternalUserDashboard || !!userInfo?.deactivatedAt}
            >
              {t('Page.Dashboard.FullReportsAd.Cta')}
            </Button>
          </span>
        </Tooltip>
      </div>

      <MoreDataModal
        onClose={handleCloseModal}
        isOpen={isMoreDataModalOpen}
        onSubmitSelection={onSubmitProductsSelection}
        defaultSelectedProducts={defaultSelectedProducts}
      />
    </>
  );
};
