import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Modal } from 'components';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  renewalDate?: Date;
  onCancel: () => void;
  onSubmit: () => void;
}
export const ConfirmRenewSubscriptionModal: FC<Props> = ({
  isOpen,
  onCancel,
  onSubmit,
  renewalDate
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onCancel} paperClassName={styles.modal}>
      <h2 className={styles.title}>
        {t('Page.RenewSubscriptionConfirm.Title')}
      </h2>

      <p className={styles.subtitle}>
        {t('Page.RenewSubscriptionConfirm.Subtitle', {
          endDate: renewalDate
        })}
      </p>

      <div className={styles.buttons}>
        <Button
          fullWidth
          size="medium"
          type="submit"
          color="secondary"
          variant="outlined"
          onClick={onSubmit}
          className={styles.button}
        >
          {t('Page.RenewSubscriptionConfirm.Confirm')}
        </Button>
        <Button
          fullWidth
          size="medium"
          color="primary"
          variant="contained"
          onClick={onCancel}
          className={styles.button}
        >
          {t('Page.RenewSubscriptionConfirm.Cancel')}
        </Button>
      </div>
    </Modal>
  );
};
