import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { trackActivity } from 'actions/userActions';
import { ReactComponent as RegenerateIcon } from 'assets/regenerate.svg';
import { ReactComponent as ThumbDownIcon } from 'assets/thumbs-down.svg';
import { ReactComponent as ThumbUpIcon } from 'assets/thumbs-up.svg';
import cn from 'classnames';
import { FeedbackModal, Tooltip } from 'components';
import { Message } from 'entities/Message.entity';
import { FeedbackTypes } from 'enums';
import { MessageTypes } from 'enums/MessageTypes.enum';
import { useAuthContext } from 'hooks';
import { useRateChatMessage, useUserCoins, useUserInfo } from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { MoreDataModal } from 'views/Conversation/MoreDataModal';

import { ChatStatement } from '../ChatStatement';
import { LimitedCompanyListNote } from '../LimitedCompanyListNote';

import styles from './styles.module.scss';

interface Props {
  data: Message;
  isLoading?: boolean;
  isLastAnswer?: boolean;
  openAuthModal?: () => void;
  isMessageSending?: boolean;
  isRateButtonHidden?: boolean;
  isDashboardButtonHidden?: boolean;
  isRegenerateMessageSending?: boolean;
  onSuggestionClick?: (text: string) => void;
  regenerateChatMessage?: (messageId: string) => void;
  onShowDashboardClick?: (data: {
    dashboardId: string;
    isPaid: boolean;
    rootIndustries?: string[] | null;
  }) => void;
  onActivateAccountClick?: () => void;
}

export const ChatMessage: FC<Props> = ({
  data: {
    id,
    type,
    statement,
    hasFeedback,
    isPaid,
    isNotFull,
    messageId,
    suggestions,
    isInterrupted,
    dashboardId,
    rootIndustries,
    isCompanyListLimited,
    requestMoreDataVisible = true,
    activateAccountVisible = false
  },
  isLoading,
  isMessageSending,
  isLastAnswer,
  isRegenerateMessageSending,
  onShowDashboardClick,
  regenerateChatMessage,
  isDashboardButtonHidden,
  isRateButtonHidden,
  onSuggestionClick,
  openAuthModal,
  onActivateAccountClick
}) => {
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  const [isMoreDataModalOpen, setMoreDataModalOpen] = useState(false);
  const [givenFeedback, setGivenFeedback] = useState<FeedbackTypes | null>(
    null
  );

  const { isAuthenticated } = useAuthContext();
  const { chatId } = useParams();
  const { t } = useTranslation();

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const {
    mutate: rateChatMessage,
    isPending: isRatePending,
    isSuccess: isRateSuccess
  } = useRateChatMessage(chatId || '', messageId || '');

  const openMoreDataModal = () => setMoreDataModalOpen(true);

  const handleOpenMoreMoreDataModal = () => {
    if (!isAuthenticated && openAuthModal) {
      openAuthModal();
      return;
    }

    openMoreDataModal();
  };

  const closeMoreDataModal = () => setMoreDataModalOpen(false);

  const hasError = id?.includes('error');

  const openFeedbackModal = (feedback: FeedbackTypes | null) => {
    if (
      !hasFeedback &&
      !isMessageSending &&
      !isRegenerateMessageSending &&
      !isRateSuccess &&
      !isRatePending
    ) {
      setGivenFeedback(feedback);
    }
  };

  const onSubmitProductsSelection = (
    products: string,
    additionalInfo?: string
  ) => {
    mixpanel?.track(MixpanelEvents.AdditionalDataRequested, {
      // eslint-disable-next-line no-restricted-globals
      'Chat link': `${location.origin}/chats/${chatId}`,
      Products: products,
      'Default Industries': rootIndustries?.join(', ') || '-',
      'Additional Information': additionalInfo
    });
  };

  const closeFeedbackModal = () => {
    if (!hasFeedback) {
      setGivenFeedback(null);
    }
  };

  const submitFeedback = (feedback: FeedbackTypes, feedbackText?: string) => {
    mixpanel?.track(MixpanelEvents.AnswerFeedback, {
      Feedback: feedback,
      'Feedback Text': feedbackText,
      'Chat ID': chatId,
      'Message ID': messageId,
      Text: statement
    });
    rateChatMessage({ text: feedbackText, feedbackType: feedback });
  };

  const handleShowDashboardClick = () => {
    if (onShowDashboardClick && dashboardId) {
      if (!isPaid) {
        trackActivity();
      }

      onShowDashboardClick({ dashboardId, isPaid: !!isPaid, rootIndustries });
    }
  };

  const regenerateMessage = (messageId?: string) => () => {
    if (regenerateChatMessage && messageId) {
      regenerateChatMessage(messageId);
    }
  };

  const answerInProgress =
    (isMessageSending || isRegenerateMessageSending) && isLastAnswer;
  const isRateAvailable = !isRateSuccess && !isRatePending && !hasFeedback;
  const hideRateButton =
    answerInProgress || isRateButtonHidden || suggestions?.length;
  const showDashboardButtonVisible =
    !!dashboardId &&
    !isDashboardButtonHidden &&
    !answerInProgress &&
    !isNotFull;
  const restartGenerationButtonVisible =
    isLastAnswer &&
    !isMessageSending &&
    !isRegenerateMessageSending &&
    isNotFull;
  const disableDashboard =
    !isPaid &&
    (userInfo?.deactivatedAt || (userInfo?.isFreeUser && !userCoins?.count));
  const actionsVisible =
    (requestMoreDataVisible && type === MessageTypes.answer) ||
    (activateAccountVisible && type === MessageTypes.answer) ||
    showDashboardButtonVisible ||
    restartGenerationButtonVisible;

  const hasFewCompanies = type === MessageTypes.answer && isCompanyListLimited;

  const handleSuggestionClick = (suggestion: string) => () => {
    if (isLoading || !onSuggestionClick || userInfo?.deactivatedAt) return;

    onSuggestionClick(suggestion);
  };

  return (
    <>
      <MoreDataModal
        isOpen={isMoreDataModalOpen}
        onClose={closeMoreDataModal}
        onSubmitSelection={onSubmitProductsSelection}
      />
      <div
        className={cn(
          styles['message-wrapper'],
          hasFewCompanies && styles['companies-list-limited']
        )}
      >
        <div
          ref={messageContainerRef}
          data-testid="chat-message"
          className={cn(styles.message, styles[type])}
        >
          {!!givenFeedback && (
            <FeedbackModal
              feedback={givenFeedback}
              onClose={closeFeedbackModal}
              onSubmitFeedback={submitFeedback}
            />
          )}
          <ChatStatement
            data={{ type, statement }}
            showCaret={isLastAnswer && isLoading}
          >
            <>
              {isInterrupted &&
                (type === MessageTypes.answer ||
                  type === MessageTypes.followup_questions) && (
                  <span className={styles['interrupted-note']}>
                    {t('Page.Chat.InterruptedNote')}
                  </span>
                )}

              {!!suggestions?.length && (
                <div className={styles.suggestions}>
                  {suggestions.map((suggestion) => (
                    <Tooltip
                      key={suggestion}
                      title={t('Page.Chat.DeactivatedAccountTooltip')}
                      disabled={!userInfo?.deactivatedAt}
                    >
                      <div
                        onClick={handleSuggestionClick(suggestion)}
                        className={cn(
                          styles.suggestion,
                          userInfo?.deactivatedAt && styles.disabled
                        )}
                      >
                        {suggestion}
                      </div>
                    </Tooltip>
                  ))}
                </div>
              )}

              <div
                className={cn(
                  styles.footer,
                  !actionsVisible && styles['end-aligned']
                )}
              >
                {actionsVisible && (
                  <div className={styles.actions}>
                    {requestMoreDataVisible && (
                      <Tooltip
                        title={t('Common.DeactivatedAccountNote')}
                        disabled={!userInfo?.deactivatedAt}
                        className={styles.tooltip}
                      >
                        <span>
                          <Button
                            size="medium"
                            color="primary"
                            variant="contained"
                            onClick={handleOpenMoreMoreDataModal}
                            disabled={!!userInfo?.deactivatedAt}
                            className={cn(
                              styles['action-button'],
                              !showDashboardButtonVisible && 'brilliance'
                            )}
                          >
                            {t('Page.Chat.RequestAdditionalData')}
                          </Button>
                        </span>
                      </Tooltip>
                    )}

                    {activateAccountVisible && (
                      <Button
                        size="medium"
                        color="primary"
                        variant="contained"
                        disabled={!userInfo?.deactivatedAt}
                        onClick={onActivateAccountClick}
                        className={styles['action-button']}
                      >
                        {t('Page.Profile.Settings.Activate.Button')}
                      </Button>
                    )}

                    {showDashboardButtonVisible && (
                      <Tooltip
                        title={
                          !userCoins?.count
                            ? t(
                                'Common.FreeSubscriptionFullDashboardButtonTooltip'
                              )
                            : t(
                                'Common.UnlockCoinsModal.DeactivatedAccountTooltip'
                              )
                        }
                        disabled={!disableDashboard}
                        className={styles.tooltip}
                      >
                        <span>
                          <Button
                            size="medium"
                            color="primary"
                            variant="contained"
                            disabled={!!disableDashboard}
                            className={cn(
                              styles['action-button'],
                              'brilliance'
                            )}
                            onClick={handleShowDashboardClick}
                          >
                            {t(
                              !isPaid && userInfo?.isFreeUser
                                ? 'Page.Chat.ForDashboard'
                                : 'Page.Chat.ForPaidDashboard'
                            )}
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                )}

                <div className={styles['additional-actions']}>
                  {restartGenerationButtonVisible && (
                    <div className={styles['regenerate-container']}>
                      <span className={styles.label}>
                        {t('Page.Chat.RestartGeneration')}
                      </span>
                      <IconButton
                        edge="end"
                        onClick={regenerateMessage(messageId)}
                      >
                        <RegenerateIcon className={styles.icon} />
                      </IconButton>
                    </div>
                  )}

                  {type === MessageTypes.answer &&
                    !hideRateButton &&
                    !hasError && (
                      <div className={styles['rate-container']}>
                        <span className={styles.label}>
                          {t('Page.Chat.RateAnswer')}
                        </span>
                        <Tooltip
                          title={t('Common.FeedbackModal.DisabledTooltip')}
                          disabled={!hasFeedback}
                          className={styles.tooltip}
                        >
                          <IconButton
                            edge="end"
                            className={cn(
                              styles['rate-container-button'],
                              !isRateAvailable && styles.disabled
                            )}
                            onClick={() =>
                              openFeedbackModal(FeedbackTypes.NEGATIVE)
                            }
                          >
                            <ThumbDownIcon className={styles.icon} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={t('Common.FeedbackModal.DisabledTooltip')}
                          disabled={!hasFeedback}
                          className={styles.tooltip}
                        >
                          <IconButton
                            edge="end"
                            className={cn(
                              styles['rate-container-button'],
                              !isRateAvailable && styles.disabled
                            )}
                            onClick={() =>
                              openFeedbackModal(FeedbackTypes.POSITIVE)
                            }
                          >
                            <ThumbUpIcon className={styles.icon} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                </div>
              </div>
            </>
          </ChatStatement>
        </div>

        {hasFewCompanies && <LimitedCompanyListNote />}
      </div>
    </>
  );
};
