import { date, deserialize, serializable } from 'serializr';

export class UserCoin {
  @serializable
  id = 0;

  @serializable
  userId = 0;

  @serializable
  count = 0;

  @serializable
  requestsCount = 0;

  // TODO: Change to Coin on BE
  @serializable
  freePlanCreditsPurchased = false;

  @serializable
  hasUsedPremiumPlan = false;

  @serializable(date())
  monthlyDashboardRequestsRefreshDate: Date = new Date();

  @serializable(date())
  monthlyDashboardRequestsCount = 0;

  @serializable(date())
  refreshCountAt: Date = new Date();

  @serializable(date())
  initialAccessStartedAt: Date = new Date();

  @serializable(date())
  limitedAccessStartedAt: Date = new Date();

  static deserialize(json: Object | string): UserCoin {
    return deserialize(UserCoin, json);
  }
}
