import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Modal, SubTitle } from 'components';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  industry: string;
  hasCoins: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DashboardFilterModal: FC<Props> = ({
  isOpen = false,
  hasCoins,
  industry,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();

  const handleCancel = () => onClose();

  const handleConfirm = () => onConfirm();

  const modalDetails = hasCoins
    ? {
        title: t('Page.Dashboard.DashboardFilterModal.Title', { industry }),
        subtitle: t('Page.Dashboard.DashboardFilterModal.Subtitle', {
          industry
        }),
        submitButton: t('Page.Dashboard.DashboardFilterModal.Confirm'),
        cancelButton: t('Page.Dashboard.DashboardFilterModal.Cancel')
      }
    : {
        title: t('Page.Dashboard.DashboardFilterModalNoCoins.Title'),
        subtitle: t('Page.Dashboard.DashboardFilterModalNoCoins.Subtitle', {
          industry
        }),
        submitButton: t('Page.Dashboard.DashboardFilterModalNoCoins.Confirm'),
        cancelButton: t('Page.Dashboard.DashboardFilterModalNoCoins.Cancel')
      };

  return (
    <Modal
      title={modalDetails.title}
      titleClassName={styles.title}
      className={styles.container}
      isOpen={isOpen}
    >
      <SubTitle className={styles.subtitle}>{modalDetails.subtitle}</SubTitle>

      <div className={styles.buttons}>
        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleConfirm}
          className={styles.button}
        >
          {modalDetails.submitButton}
        </Button>
        <Button
          size="medium"
          color="secondary"
          variant="outlined"
          className={styles.button}
          onClick={handleCancel}
        >
          {modalDetails.cancelButton}
        </Button>
      </div>
    </Modal>
  );
};
