import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { updateUserInfo, UpdateUserInfoData } from 'actions/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';
import mixpanel, { MixpanelEvents } from 'mixpanel';

export const useUpdateUserInfo = (hasSuccessSnackbar = true) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { i18n } = useTranslation();

  return useMutation<User, ApiError, UpdateUserInfoData>({
    mutationFn: async (userInfo: UpdateUserInfoData) => {
      const updatedUserInfo = await updateUserInfo(userInfo);

      return updatedUserInfo;
    },
    onSuccess({
      email,
      firstName,
      gender,
      company,
      lastName,
      preferredLanguage
    }) {
      mixpanel?.track(MixpanelEvents.UpdateUserInfo, {
        Email: email,
        'First Name': firstName,
        'Last Name': lastName,
        Gender: gender,
        Company: company,
        'Preferred Language': preferredLanguage
      });

      i18n.changeLanguage(preferredLanguage);

      queryClient.invalidateQueries({
        queryKey: queryKeys.userInfo
      });

      if (hasSuccessSnackbar) {
        snackbar.success.commonSuccess(
          t('Notifications.ChangeUserInfoSuccess')
        );
      }
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
