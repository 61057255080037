import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SentryErrors } from 'enums/SentryErrors.enum';

const ignoredSentryErrors = [SentryErrors.WebGLContext];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.REACT_APP_ENVIRONMENT === 'production',
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration(),
    Sentry.replayCanvasIntegration()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    if (
      event.message &&
      !!ignoredSentryErrors.find((error) => event?.message?.includes(error))
    ) {
      return null; // Discards this event
    }
    return event;
  }
});
